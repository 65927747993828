import React from 'react';

function HorizontalColumn({ color, animation, text }) {
	return (
		<div
			className={`absolute bg-[#f8650a] top-0 left-[12.5vw] bottom-[-12.5vw] w-[100vw] h-[12.5vw] lg:left-[10vw] lg:bottom-[-10vw] lg:h-[10vw] -z-9 ${animation}`}
			style={{
				backgroundColor: color,
			}}
		></div>
	);
}

export default HorizontalColumn;
