const scrollToTop = () => {
	const duration = 5000; // milliseconds
	const startScrollY = window.scrollY;
	const scrollStep = -startScrollY / (duration / (1000 / 60)); // 60 frames per second

	const animateScroll = (startTime) => {
		const elapsedTime = performance.now() - startTime;
		const scrollY = Math.max(startScrollY + scrollStep * elapsedTime, 0);
		window.scrollTo(0, scrollY);
		if (scrollY > 0) {
			requestAnimationFrame(() => animateScroll(startTime));
		}
	};

	requestAnimationFrame(() => animateScroll(performance.now()));
};

export default scrollToTop;
