import React from 'react';
import SingleListProject from './SingleListProject';

function ProjectsList({ arr }) {
	return (
		<div className='animate-scaleLeftMobile flex flex-col ml-[-16px] sm:mr-[-29px] md:ml-[0px] md:mr-[-40px] lg:z-20 z-50'>
			{arr.map((singleObj, idx) => (
				<SingleListProject
					key={idx}
					title={singleObj.title}
					desc={singleObj.desc}
					img={singleObj.img}
					li1={singleObj.li1}
					li2={singleObj.li2}
					li3={singleObj.li3}
					area={singleObj.area}
				/>
			))}
		</div>
	);
}

export default ProjectsList;
