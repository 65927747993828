import { createSlice } from '@reduxjs/toolkit';
export const generalSlice = createSlice({
	name: 'generalSlice',
	initialState: {
		sectionHeights: [],
		projectListSectionHeight: 0,
		currentIdx: null,
		isLoading: false,
		calculateHeight: false,
		openAccordionId: null,
		openModal: false,
	},
	reducers: {
		setHeight: (state, action) => {
			state.sectionHeights = action.payload;
		},
		setListHeight: (state, action) => {
			state.projectListSectionHeight = action.payload;
		},
		setCurrentIdx: (state, action) => {
			state.currentIdx = action.payload;
		},
		setIsLoading: (state, action) => {
			state.isLoading = action.payload;
		},
		setCalculateHeight: (state, action) => {
			state.calculateHeight = action.payload;
		},
		setOpenAccordionId: (state, action) => {
			state.openAccordionId = action.payload;
		},
		setOpenModal: (state, action) => {
			state.openModal = action.payload;
		},
	},
});

export const {
	setHeight,
	setListHeight,
	setCurrentIdx,
	setIsLoading,
	setCalculateHeight,
	setOpenAccordionId,
	setOpenModal,
} = generalSlice.actions;

export default generalSlice.reducer;
