import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenModal } from '../../../store/generalStore';
import { TfiClose } from 'react-icons/tfi';

export default function Example() {
	const { openModal } = useSelector((state) => state.generalSlice);
	const dispatch = useDispatch();

	const cancelButtonRef = useRef(null);

	return (
		<>
			{openModal && (
				<Transition.Root
					show={openModal}
					as={Fragment}
				>
					<Dialog
						as='div'
						className='relative z-40 '
						initialFocus={cancelButtonRef}
						onClose={() => dispatch(setOpenModal(false))}
					>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0'
							enterTo='opacity-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100'
							leaveTo='opacity-0'
						>
							<div className='fixed inset-0 bg-slate-600 bg-opacity-20 transition-opacity' />
						</Transition.Child>

						<div className='fixed inset-0 z-10 overflow-y-auto'>
							<div className='flex justify-center items-center text-left m-8'>
								<Transition.Child
									as={Fragment}
									enter='ease-out duration-300'
									enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
									enterTo='opacity-100 translate-y-0 sm:scale-100'
									leave='ease-in duration-200'
									leaveFrom='opacity-100 translate-y-0 sm:scale-100'
									leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
								>
									<Dialog.Panel className='relative h-[80vh] lg:w-[60vw] xl:w-[50vw] my-10 p-8 transform overflow-y-auto rounded-lg bg-white shadow-xl transition-all animate-fadeInOffers'>
										<div className='bg-white '>
											<TfiClose
												onClick={() => dispatch(setOpenModal(false))}
												className='absolute right-6 top-6 cursor-pointer lg:hover:opacity-70'
											/>
											<h3 className='font-bold mb-[2px]'>Pagrindas</h3>
											<p className='mb-4'>
												Konstrukcijai naudojamos medienos išmatavimus galima
												pasirinkti (5x5, 10x5, 15x5, 20x5) cm. Naudojamos
												medžiagos: apšiltimo sluoksnis, difuzinė plėvelė,
												drėgmei atspari jūrinės faneros plokštė, sertifikuota
												mastika hidroizoliacijai, tinklelis nuo graužikų.
											</p>
											<h3 className='font-bold mb-[2px]'>Grindys</h3>
											<p className='mb-4'>
												Pagrindas išklojamas 22 mm OSB plokšte, pasirinktos
												dangos paklotas, laminatas ar parketlentė.
											</p>
											<h3 className='font-bold mb-[2px]'>Sienos</h3>
											<p className='mb-4'>
												Medienos išmatavimai pasirenkami (5x5, 10x5, 15x5, 20x5
												cm). Galimas dvigubas karkasas (+5 cm iš vidaus + 5 cm
												iš išorės). Tokiu būdu galimas sienos ir apšiltinimo
												storis iki 30 cm (atitinka A++ klasę), difuzinė plėvė iš
												išorės, priešgarinė plėvė iš vidaus.
											</p>
											<h3 className='font-bold mb-[2px]'>Stogas</h3>
											<p className='mb-4'>
												Mediena pasirenkama (5x5, 10x5, 15x5, 20x5 cm). Galimas
												dvigubas karkasas (+10 cm iš vidaus). Tokiu būdu galimas
												stogo ir apšiltinimo storis iki 30 cm (atitinka A++
												klasę), difuzinė plėvė iš išorės, priešgarinė plėvė iš
												vidaus. Pageidaujant sumontuojama lietaus nuvedimo
												sistema.
											</p>
											<h3 className='font-bold mb-[2px]'>Stogo danga</h3>
											<p className='mb-4'>
												Galima pasirinkti: plieninės/modulinės čerpės, Classic
												stogo danga, prilydoma bituminė danga ir kt.
											</p>
											<h3 className='font-bold mb-[2px]'>Išorės apdaila</h3>
											<p className='mb-4'>
												Tai classic skarda, dailylentės. Ventiliuojamas dvigubo
												karkaso fasadas užtikrina fasado ventiliaciją ir
												minimizuoja pelėsio galimybę.
											</p>
											<h3 className='font-bold mb-[2px]'>Apšiltinimas</h3>
											<p className='mb-4'>
												Jei pasirenkamas dvigubas karkasas, storis iki 30 cm,
												kitu atveju - pagal medienos storį. Naudojama mineralinė
												vata KNAUF. Tai gera šilumos izoliacija, todėl tinka
												gyventi ir žiemą, bei 3 kartus geresnė garso izoliacija
												nei poliuretano putos. Nemeslis gali būti ir
												nešiltintas.
											</p>
											<h3 className='font-bold mb-[2px]'>Elektra</h3>
											<p className='mb-4'>
												Pilna elektros instaliacija (LED apšvietimas,
												jungikliai, rozetės, paskirstymo dėžė su automatiniais
												saugikliais ir nuotėkio rėle).
											</p>
											<h3 className='font-bold mb-[2px]'>Plėvelės</h3>
											<p className='mb-4'>
												Flextec 140 - trisluoksnė labai pralaidi stogo membrana,
												skirta apšiltintiems ir vėdinamiems šlaitiniams stogams,
												taip pat ir su pilna lentų danga. Didesnis atsparumas
												plyšimui padidina darbo komfortą. SISTEMA SK2 - plėvelė
												su 2 lipniomis juostomis. Eurovent® AKTIV - dviejų
												sluoksnių garų izoliuojanti membrana. Ją sudaro
												polipropileno vilnos sluoksnis ir funkcinė plėvelė, kuri
												leidžia nedideliam vandens garų kiekiui migruoti į
												palėpę iš pastato vidaus, o prireikus - iš palėpės į
												vidų. Aktyviųjų membranų savybės - drėgmės pertekliaus
												drenažas, kurio neužtikrina įprastos garus
												izoliuojančios plėvelės.
											</p>
											<h3 className='font-bold mb-[2px]'>Santechnika</h3>
											<p className='mb-4'>
												Vandens ir nuotekų instaliacija: WC - dušas, praustuvas,
												unitazas, boileris; virtuvė - kriauklė, skalbimo mašina,
												indaplovė. Papildomai galime įrengti šilumos siurblį
												oras/oras patalpų šildymui/vėsinimui.
											</p>
											<h3 className='font-bold mb-[2px]'>
												Projektą galime koreguoti pagal Jūsų poreikius ir
												pageidavimus.
											</h3>
											<h3 className='font-bold mb-[2px]'>
												Papildomai teikiame vietos paruošimo, pamatų.
											</h3>
										</div>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</Dialog>
				</Transition.Root>
			)}
		</>
	);
}
