import React, {useEffect, useState} from 'react';
import {HiOutlineChevronRight, HiOutlineChevronLeft} from 'react-icons/hi';
import {TfiPlus} from 'react-icons/tfi';
import {useDispatch, useSelector} from 'react-redux';
import {setIsLoading, setOpenModal} from '../../store/generalStore';
import Modal from './Modal/Modal';

function SingleListProject({title, desc, li1, li2, li3, area, img}) {
	const dispatch = useDispatch();
	const [imgArr, setImgArr] = useState([]);

	useEffect(() => {
		setImgArr(img);
	}, []);

	const {openModal} = useSelector((state) => state.generalSlice);

	const [currentImageIndex, setCurrentImageIndex] = useState(0);
	const [currentImageBox, setCurrentImageBox] = useState(0);

	const handleClick = () => {
		dispatch(setIsLoading(true));
		const nextIndex = (currentImageIndex + 1) % imgArr.length;
		setCurrentImageIndex(nextIndex);
		if (currentImageBox === 3) {
			setCurrentImageBox(0);
		} else {
			setCurrentImageBox(currentImageBox + 1);
		}
	};
	const handleClickBack = () => {
		dispatch(setIsLoading(true));
		const nextIndex = currentImageIndex === 0 ? imgArr.length - 1 : currentImageIndex - 1;
		setCurrentImageIndex(nextIndex);
		if (currentImageBox === 0) {
			setCurrentImageBox(3);
		} else {
			setCurrentImageBox(currentImageBox - 1);
		}
	};
	const handleImageLoad = () => {
		dispatch(setIsLoading(false));
	};

	const currentImageSrc = imgArr[currentImageIndex];

	return (
		<div className='flex mr-2 flex-col-reverse md:mb-8 pl-8 md:pl-0 md:flex-row '>
			<div className='flex flex-col pl-2 mt-[-30px] items-between md:justify-center  md:mt-0 md:w-[226px] lg:w-[307px] xl:w-[337px] sm:mr-[8px] z-10 text-[16px] '>
				<h2 className='text-[16px] text-black md:font-normal lg:font-normal font-semibold sm:mt-0 mb-2 sm:mb-1 sm:text-[21px]'>{title}</h2>
				<p className='text-[#333333] w-[75vw] md:w-auto mb-2 sm:mb-[10px]'>{desc}</p>
				<ul className='flex flex-col gap-1 mb-4'>
					<li>{li1}</li>
					<li>{li2}</li>
					<li>{li3}</li>
					<li>{area}</li>
				</ul>
				<button
					className='text-left flex pb-8 md:pb-0 lg:hover:opacity-70'
					onClick={() => dispatch(setOpenModal(true))}
				>
					<TfiPlus
						size={24}
						className='pr-2'
					/>
					Skaityti daugiau
				</button>
				{openModal && <Modal />}
			</div>
			<div className='relative select-none'>
				<img
					onLoad={handleImageLoad}
					className='w-[80vw] h-[298px] md:w-[55vw] md:h-[333px] lg:h-[400px] lg:w-[50vw] xl:w-[45vw] xl:h-[455px] 2xl:w-[40vw] border-t-[8px] border-r-[8px] border-b-[49px] border-[#DFC1AE] lg:border-r-[32px] lg:border-t-[32px] lg:border-b-[32px] md:border-r-[16px] md:border-t-[16px] md:border-b-[24px] object-cover '
					src={currentImageSrc}
					alt={title}
				/>
				<div className='absolute flex gap-1 sm:gap-2 bottom-[2.2rem] lg:bottom-[0.8rem] right-[48%] md:right-[43%] md:bottom-[0.5rem] lg:right-[46%]'>
					<div className={currentImageBox === 0 ? 'h-[4px] w-[4px] sm:h-[6px] sm:w-[6px] md:h-[8px] md:w-[8px] bg-[#808080]' : 'h-[4px] w-[4px] sm:h-[6px] sm:w-[6px] md:h-[8px] md:w-[8px] bg-white'}></div>
					<div className={currentImageBox === 1 ? 'h-[4px] w-[4px] sm:h-[6px] sm:w-[6px] md:h-[8px] md:w-[8px] bg-[#808080]' : 'h-[4px] w-[4px] sm:h-[6px] sm:w-[6px] md:h-[8px] md:w-[8px] bg-white'}></div>
					<div className={currentImageBox === 2 ? 'h-[4px] w-[4px] sm:h-[6px] sm:w-[6px] md:h-[8px] md:w-[8px] bg-[#808080]' : 'h-[4px] w-[4px] sm:h-[6px] sm:w-[6px] md:h-[8px] md:w-[8px] bg-white'}></div>
					<div className={currentImageBox === 3 ? 'h-[4px] w-[4px] sm:h-[6px] sm:w-[6px] md:h-[8px] md:w-[8px]  bg-[#808080]' : 'h-[4px] w-[4px] sm:h-[6px] sm:w-[6px] md:h-[8px] md:w-[8px] bg-white'}></div>
				</div>
				<HiOutlineChevronLeft
					onClick={handleClickBack}
					className='absolute cursor-pointer lg:hover:scale-105 top-[34%] md:top-[40%] lg:top-[45%] left-[-0.5rem] md:left-[-0.5rem] text-[3rem] text-white text-4xl sm:text-6xl ml-2 z-30'
				/>
				<HiOutlineChevronRight
					onClick={handleClick}
					className='absolute cursor-pointer lg:hover:scale-105 top-[34%] md:top-[40%] lg:top-[45%] right-0.5 lg:right-6 text-[3rem] text-white text-4xl sm:text-6xl mr-4 sm:mr-11 md:mr-4 lg:mr-4 xl:mr-4 2xl:mr-4 z-50'
				/>
			</div>
		</div>
	);
}

export default SingleListProject;
