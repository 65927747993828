import shed1 from '../../../assets/sandeliukai/picnic-area-with-barbecue-grill-table-chairs.webp';
import shed2 from '../../../assets/sandeliukai/simple-patio-with-vegetation-inside.webp';
import shed3 from '../../../assets/sandeliukai/small-children-houses-made-wood-italian-park.webp';
import shed4 from '../../../assets/sandeliukai/gray-new-shed.webp';

export const shedsArr = [
	{
		title: 'Sandėliukas Nr. 1',
		// desc: 'Gaminame modulinius namelius pagal Jūsų norus. Galime įmontuoti šikaną, saulės batarkes ir pan. Tik būkit geri turėkit daug babkių.',
		li1: 'Bendras plotas: Pagal poreikius',
		li2: 'Projekto trukmė: iki 2 mėn.',
		li3: 'Kaina: 300 Eur/m²',
		img: [shed1, shed2, shed3, shed4],
	},
];
