import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { VscChromeClose } from 'react-icons/vsc';
import logo from '../../assets/img/logo.webp';
import scrollToProject from '../../helpers/scrollToProject';
import scrollToSection from '../../helpers/scrollToSection';
import scrollToTop from '../../helpers/scrollToTop';

function Nav() {
	const [nav, setNav] = useState(false);
	const [projects, setProjects] = useState(false);
	const menuRef = useRef();

	const [scrollTop, setScrollTop] = useState(0);

	useEffect(() => {
		const handleScroll = (event) => {
			setScrollTop(window.scrollY);
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const handleClickOutside = (event) => {
		if (menuRef.current && !menuRef.current.contains(event.target)) {
			setNav(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleNav = () => {
		setProjects(false);
		setNav(!nav);
	};

	return (
		<div
			className={` lg:w-full w-full fixed top-0 z-40 ${
				scrollTop > 1
					? 'lg:duration-500 lg:bg-[#ffffffb2] lg:shadow-md'
					: 'lg:duration-500 lg:bg-white'
			}`}
		>
			<div className='max-w-[1200px] mx-auto'>
				<div className='hidden lg:flex lg:justify-between lg:items-center lg:mx-2'>
					<img
						onClick={() => scrollToTop()}
						src={logo}
						alt='lol'
						className='w-[130px] cursor-pointer '
					/>

					<ul className='menu menu-horizontal gap-[2.6vw] lg:mr-[4.1vw]'>
						<li
							onClick={() => scrollToSection('about')}
							className='p-[10px] cursor-pointer duration-200 active:scale-[1.02]'
						>
							Apie mus
						</li>
						<li
							onClick={() => scrollToSection('offers')}
							className='p-[10px] cursor-pointer duration-200 active:scale-[1.02]'
						>
							Paslaugos
						</li>
						<li className='p-[10px] cursor-pointer'>
							Projektai
							<ul className='text-white w-max bg-[#4e4e4ea8] translate-x-[-16%] '>
								<li
									onClick={() => {
										scrollToProject('houses');
									}}
									className='py-[14px] px-[33px] cursor-pointer lg:hover:bg-[#5a5a5a56] '
								>
									Namukai
								</li>
								<li
									onClick={() => {
										scrollToProject('saunas');
									}}
									className='py-[14px] px-[33px] cursor-pointer lg:hover:bg-[#5a5a5a56]'
								>
									Pirtys
								</li>
								<li
									onClick={() => {
										scrollToProject('sheds');
									}}
									className='py-[14px] px-[33px] cursor-pointer lg:hover:bg-[#5a5a5a56]'
								>
									Sandėliukai
								</li>
								<li
									onClick={() => {
										scrollToProject('terrace');
									}}
									className='py-[14px] px-[33px] cursor-pointer lg:hover:bg-[#5a5a5a56]'
								>
									Terasos / Pastogės
								</li>
							</ul>
						</li>
						<li
							onClick={() => scrollToSection('contacts')}
							className='p-[10px] cursor-pointer duration-200 active:scale-[1.02]'
						>
							Kontaktai
						</li>
					</ul>
				</div>
				<div className='flex flex-row-reverse justify-between w-full text-black lg:hidden'>
					{!nav ? (
						<AiOutlineMenu
							size={45}
							className='cursor-pointer mt-4 mr-4 sm:mt-8 sm:mr-8 z-[100]'
							onClick={handleNav}
						/>
					) : (
						<VscChromeClose
							size={45}
							className='cursor-pointer mt-4 mr-4 sm:mt-8 sm:mr-8 z-[100]'
							onClick={handleNav}
						/>
					)}
				</div>

				<div
					ref={menuRef}
					className={
						nav
							? ' fixed top-0 h-max w-full bg-[#808080e8] ease-in-out duration-500 z-[90]'
							: ' ease-in-out duration-500 fixed top-[-100%] w-full z-[90]'
					}
				>
					<ul className='text-black font-medium flex flex-col items-center mt-[6px] sm:mt-[22px]'>
						<li
							onClick={() => {
								setNav(false);
								scrollToSection('about');
							}}
							className='text-[21px] py-4 cursor-pointer lg:hover:bg-[#33333331] ease-in-out duration-300 text-right w-full translate-x-[-38%]'
						>
							Apie mus
						</li>
						<li
							onClick={() => {
								setNav(false);
								scrollToSection('offers');
							}}
							className='text-[21px] py-4 cursor-pointer lg:hover:bg-[#33333331] ease-in-out duration-300 text-right w-full translate-x-[-37%]'
						>
							Paslaugos
						</li>
						<li
							className='text-[21px] py-4 cursor-pointer lg:hover:bg-[#33333331] ease-in-out duration-300 text-right w-full translate-x-[-39%]'
							onClick={() => setProjects(!projects)}
						>
							Projektai
						</li>
						<li
							className={
								projects
									? 'text-white bg-[#3333337e] font-medium ease-in-out duration-500 h-max w-full'
									: 'text-white ease-in-out duration-500 h-[0] w-full'
							}
						>
							{projects && (
								<ul className='flex flex-col justify-center translate-x-[-20%] gap-1'>
									<li
										onClick={() => {
											setNav(false);
											scrollToSection('houses');
										}}
										className='text-[21px] py-4 cursor-pointer lg:hover:bg-[#33333331] ease-in-out duration-300 text-right'
									>
										Namukai
									</li>
									<li
										onClick={() => {
											setNav(false);
											scrollToSection('saunas');
										}}
										className='text-[21px] py-4 cursor-pointer lg:hover:bg-[#33333331] ease-in-out duration-300 text-right'
									>
										Pirtys
									</li>
									<li
										onClick={() => {
											setNav(false);
											scrollToSection('sheds');
										}}
										className='text-[21px] py-4 cursor-pointer lg:hover:bg-[#33333331] ease-in-out duration-300 text-right'
									>
										Sandėliukai
									</li>
									<li
										onClick={() => {
											setNav(false);
											scrollToSection('terrace');
										}}
										className='text-[21px] py-4 cursor-pointer lg:hover:bg-[#33333331] ease-in-out duration-300 text-right'
									>
										Terasos / Pastogės
									</li>
								</ul>
							)}
						</li>
						<li
							onClick={() => {
								setNav(false);
								scrollToSection('contacts');
							}}
							className='text-[21px] py-4 cursor-pointer lg:hover:bg-[#33333331] ease-in-out duration-300 text-right w-full translate-x-[-38%]'
						>
							Kontaktai
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}

export default Nav;
