import React from 'react';
import { GoPlus } from 'react-icons/go';
import { HiMinus } from 'react-icons/hi';

const Accordion = ({ id, title, description, isOpen, onClick }) => {
	const handleClick = () => {
		onClick(id);
	};

	return (
		<div className='mb-4 md:max-w-[70vw] xl:w-[100%]'>
			<div
				className='flex items-center gap-2 cursor-pointer '
				onClick={handleClick}
			>
				{isOpen === id ? <HiMinus size={18} /> : <GoPlus size={18} />}
				<p className='text-[21px] font-400'>{title}</p>
			</div>

			{isOpen === id ? (
				<p className='pl-1 pr-2 py-2 text-base text-[#333333] animate-fadeInOffers'>
					{description}
				</p>
			) : (
				false
			)}
		</div>
	);
};

export default Accordion;
