import React, { useState } from 'react';
import { GoPlus } from 'react-icons/go';
import { HiMinus } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { setCalculateHeight } from '../../store/generalStore';

const MainAccordion = ({ title }) => {
	const [isOpen, setIsOpen] = useState(false);
	const { calculateHeight } = useSelector((state) => state.generalSlice);

	const dispatch = useDispatch();
	const handleClick = () => {
		setIsOpen(!isOpen);
		dispatch(setCalculateHeight(!calculateHeight));
	};

	return (
		<div className='mb-10 mt-8 md:max-w-[70vw] md:mb-[65px] xl:hidden'>
			<div
				className='flex gap-2 cursor-pointer justify-between'
				onClick={handleClick}
			>
				<p className='text-[21px] md:text-[28px] font-400 md:mb-0 pr-[1rem] mb-4'>
					{title}
				</p>
			</div>

			<ul className='px-4 py-2 list-disc animate-fadeInOffers'>
				<li className='mb-1'>Naudojamos tik aukščiausios kokybės medžiagos.</li>
				<li className='mb-1'>Gamyba atliekama mūsų gamykloje.</li>
				<li className='mb-1'>Greita statyba.</li>
				<li className='mb-1'>Nereikalingi jokie leidimai.</li>
				<li className='mb-1'>Galimybė sujungti kelis modulinius pastatus.</li>
				<li className='mb-1'>
					Tinkamai apšiltintas namas, tinkantis gyvenimui žiemą.
				</li>
				<li className='mb-1'>
					Mobilus - galima perkelti į norimą vietą, lengvai transportuojamas.
				</li>
				<li className='mb-1'>Greitas pristatymas.</li>
				<li className='mb-1'>Ekologiškas pasirinkimas.</li>
				<li className='mb-1'>
					Projektai koreguojami pagal poreikius ir pageidavimus.
				</li>
				<li className='mb-1'>
					Teikiame vietos paruošimo, pamatų irengimo, pristatymo ir montavimo
					darbų paslaugas.
				</li>
			</ul>
		</div>
	);
};

export default MainAccordion;
