import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setCalculateHeight, setCurrentIdx} from '../../store/generalStore';
import HorizontalColumn from '../Columns/HorizontalColumn';
import SingleProject from './SingleProject';
import {housesArr} from './ProjectArrays/housesArr';
import {saunasArr} from './ProjectArrays/saunasArr';
import houseImg from '../../assets/namukai/wooden-houses-by-river-trees-twigliht-sky-3d-rendering.webp';
import saunaImg from '../../assets/pirtys/relaxing-barrel-sauna-form-small-house-made-light-wood-field.webp';
import shedImg from '../../assets/sandeliukai/wooden-shed-with-wooden-door-wooden-door.webp';
import terraceImg from '../../assets/terasos/modern-villa-with-pool-deck.webp';
import {terracesArr} from './ProjectArrays/terracesArr';
import {shedsArr} from './ProjectArrays/shedsArr';

function Projects() {
	const dispatch = useDispatch();
	const {currentIdx, calculateHeight} = useSelector((state) => state.generalSlice);

	useEffect(() => {
		dispatch(setCalculateHeight(!calculateHeight));
	}, [setCalculateHeight, currentIdx]);

	const data = [
		{
			id: 'houses',
			title: 'Moduliniai nameliai',
			// subtitle: 'Gyvenamasis arba rekreacinis pastatas',
			desc: 'Šiuolaikišką ir modernų namelį pagaminsime pagal Jūsų poreikius',
			img: houseImg,
			arr: housesArr,
		},
		{
			id: 'saunas',
			title: 'Pirtys',
			// subtitle: 'Rekreaciniai pastatai',
			desc: 'Sukursime šilumos oazę pagal Jūsų norus',
			img: saunaImg,
			arr: saunasArr,
		},
		{
			id: 'sheds',
			title: 'Sandėliukai',
			// subtitle: 'Sandėliukai ir panašiai',
			desc: 'Funkcionali erdvė visiems Jūsų sodo pagalbininkams',
			img: shedImg,
			arr: shedsArr,
		},
		{
			id: 'terrace',
			title: 'Terasos / pastogės',
			// subtitle: 'Ir gal pavėsinės opa',
			desc: 'Aukščiausios kokybės terasos. Nuo projekto parengimo iki profesionalaus įgyvendinimo',
			img: terraceImg,
			arr: terracesArr,
		},
	];

	return (
		<div
			id='projects'
			className='container mx-auto max-w-[1200px] relative pt-[12.5vw] lg:pt-[10vw] '
		>
			<HorizontalColumn
				color='#DFC1AE'
				animation={'anime3'}
			/>
			<div className='flex max-w-[85vw] flex-col mx-4 lg:max-w-[75vw] xl:max-w-[69vw] 2xl:max-w-[60vw] xl:mx-0 mt-8'>
				{data.map((proj, i) => {
					return (
						<div
							key={i}
							className='lg:z-20 z-[20]'
						>
							<div
								id={proj.id}
								className='relative'
								onClick={() => {
									dispatch(setCurrentIdx(i));
									if (currentIdx === i) dispatch(setCurrentIdx(null));
								}}
							>
								<SingleProject
									title={proj.title}
									subtitle={proj.subtitle}
									desc={proj.desc}
									img={proj.img}
									index={i}
									arr={proj.arr}
								/>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default Projects;
