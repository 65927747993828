import React from 'react';
import logo from '../../assets/img/logo.webp';
import '../../styles/scssAnimations.scss';

function Hero() {
	return (
		<div className='container mx-auto h-[80vh] flex justify-center bg-transparent'>
			<img
				className='lg:hidden max-w-[90vw] translate-y-[-20px] mx-[22px] md:mx-[40px] z-20 object-contain'
				src={logo}
				alt='project mini logo'
			/>
			<div className='hidden lg:flex w-full mx-4 lg:self-end'>
				<h1 className='text-center lg:text-[127px] xl:text-[180px] w-full text-black lg:leading-[6.2rem] xl:leading-[8.55rem]  z-20 hero-logo'>
					PROJECT MINI
				</h1>
			</div>
		</div>
	);
}

export default Hero;
