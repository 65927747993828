import React, {useState} from 'react';
import plus from '../../assets/img/plus.webp';

import ProjectsList from './ProjectsList';

function SingleProject({title, subtitle, desc, img, arr}) {
	const [listOpen, setListOpen] = useState(false);

	return (
		<div>
			<div
				className='cursor-pointer relative flex flex-col md:flex-row md:items-center gap-2 md:gap-3 lg:z-20 z-50 duration-500 ease-in-out lg:hover:brightness-75 lg:hover:shadow-2xl lg:hover:bg-[#f1ddd14f] pr-2 mb-8'
				onClick={() => {
					setListOpen(!listOpen);
				}}
			>
				<div>
					<img
						className='max-w-[80vw] min-h-[298px] md:max-w-[391px] md:min-h-[320px] lg:min-w-[506px] lg:h-[418px] xl:min-w-[636px] xl:h-[418px] object-cover'
						src={img}
						alt={title}
					/>
				</div>
				<div className='max-w-[360px] 2xl:max-w-[380px] relative'>
					<h2 className='project-desc-h2'>{title}</h2>
					<h3 className='project-desc-h3'>{subtitle}</h3>
					<p className='project-desc-p'>{desc}</p>
				</div>
				<img
					className={`lg:h-10 h-8 hidden cursor-pointer md:block self-end right-2 bottom-2 absolute ${listOpen ? 'rotate-45 duration-300 ease-linear' : 'duration-200 ease-linear'} 
			}`}
					src={plus}
					alt='plus sign'
				/>
			</div>
			{listOpen && <ProjectsList arr={arr} />}
		</div>
	);
}

export default SingleProject;
