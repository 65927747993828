import terr1 from '../../../assets/terasos/rooftop-deck-with-wooden-floors-awning.webp';
import terr2 from '../../../assets/terasos/neat-patio-with-sitting-area (1).webp';
import terr3 from '../../../assets/terasos/picture-wooden-building-forest.webp';
import terr4 from '../../../assets/terasos/3d-rendered-black-pergola.webp';

export const terracesArr = [
	{
		title: 'Terasa Nr. 1',
		// desc: 'Gaminame modulinius namelius pagal Jūsų norus. Galime įmontuoti šikaną, saulės batarkes ir pan. Tik būkit geri turėkit daug babkių.',
		li1: 'Bendras plotas: Pagal poreikius',
		li2: 'Projekto trukmė: iki 2 mėn.',
		li3: 'Kaina: Pagal projektą',
		img: [terr1, terr2, terr3, terr4],
	},
];
