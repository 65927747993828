import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import About from './components/About/About';
import Columns from './components/Columns/Columns';
import Footer from './components/Footer/Footer';
import Hero from './components/Hero/Hero';
import Nav from './components/Nav/Nav';
import Offers from './components/Offers/Offers';
import Projects from './components/Projects/Projects';
import {setHeight} from './store/generalStore';
import './styles/App.css';
import './styles/tailwind.css';
import Partners from './components/Partners/Partners';

function App() {
	const [trigger, setTrigger] = useState(false);
	const dispatch = useDispatch();

	const {isLoading, calculateHeight} = useSelector((state) => state.generalSlice);

	useLayoutEffect(() => {
		const sections = document.querySelectorAll('.section');
		const heights = [];

		sections.forEach((section) => {
			heights.push(section.offsetHeight);
		});

		dispatch(setHeight(heights));
	}, [dispatch, trigger, isLoading, calculateHeight]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setTrigger(true);
		}, 1000);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	return (
		<div className='relative overflow-hidden'>
			<Columns />
			<Nav />
			<div className='section'>
				<Hero />
			</div>
			<div className='section'>
				<About />
			</div>
			<div className='section'>
				<Offers />
			</div>
			<div className='section'>
				<Projects />
			</div>
			<div className='section'>
				<Footer />
			</div>
		</div>
	);
}

export default App;
