import React, { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import map from '../../assets/img/map.webp';
import HorizontalColumn from '../Columns/HorizontalColumn';
import errorPng from '../../assets/img/error.webp';
import logo from '../../assets/img/logo.webp';
import Partners from '../Partners/Partners';

function Footer() {
	const [success, setSuccess] = useState(false);
	const [checkbox, setCheckbox] = useState(false);
	const [checkBoxError, setCheckBoxError] = useState(false);

	const formRef = useRef();

	useEffect(() => {
		if (success) {
			const timer = setTimeout(() => {
				setSuccess(false);
			}, 3000);

			return () => {
				clearTimeout(timer);
			};
		}
	}, [success]);

	const [name, setName] = useState({
		value: '',
		error: false,
		msg: '',
	});
	const [email, setEmail] = useState({
		value: '',
		error: false,
		msg: '',
	});
	const [phone, setPhone] = useState({
		value: '',
		error: false,
		msg: '',
	});
	const [desc, setDesc] = useState({
		value: '',
		error: false,
		msg: '',
	});

	const handleSubmit = (e) => {
		e.preventDefault();

		const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

		let error = false;

		const data = {
			name: name.value,
			email: email.value,
			phone: phone.value,
			desc: desc.value,
		};

		if (data.name === '') {
			setName({
				...name,
				error: true,
				msg: 'Vardą turi sudaryti nuo 3 iki 20 simbolių',
			});
			error = true;
		}
		if (data.email === '') {
			setEmail({
				...email,
				error: true,
				msg: 'Neteisingai įvestas el. pašto adresas.',
			});
			error = true;
		}
		if (data.phone.length < 8 || data.phone.length > 15) {
			setPhone({
				...phone,
				error: true,
				msg: 'Telefono numerį turi sudaryti nuo 9 iki 15 skaitmenų',
			});
			error = true;
		}
		if (!regex.test(data.email)) {
			if (email.error) return;
			setEmail({ ...email, error: true, msg: 'Trūksta el. pašto simbolių' });
			error = true;
		}

		if (!checkbox) {
			return setCheckBoxError(true);
		}

		if (error) return;

		emailjs
			.sendForm(
				'service_2ybmyla',
				'template_l6fdfwk',
				formRef.current,
				'm9z-7xzc4QRlxs1bx'
			)
			.then(
				(result) => {
					setSuccess(true);
					setCheckbox(false);
				},
				(error) => {}
			);

		setSuccess(true);
		setName({ value: '', error: false, msg: '' });
		setEmail({ value: '', error: false, msg: '' });
		setPhone({ value: '', error: false, msg: '' });
		setDesc({ value: '', error: false, msg: '' });
	};

	return (
		<div
			id='contacts'
			className='mx-auto pt-[21vw] 2xl:pt-64 pb-2 md:pt-[22vw] lg:pt-[14vw] lg:ml-0 max-w-[1200px] sm:mx-[16px] 2xl:mx-auto relative'
		>
			<HorizontalColumn
				color='#BE9D88'
				animation={'anime4'}
				text='Kontaktai'
			/>
			<img
				className='lg:hidden hidden md:block md:max-w-[170px] md:mx-[16px]'
				src={logo}
				alt='project mini logo'
			/>
			<div className='flex flex-col md:flex-row-reverse md:justify-center xl:justify-between md:items-flex-start sm:gap-[64px] mx-4 lg:gap-[128px] lg:ml-[120px] 2xl:ml-[0] lg:mr-[12.5vw] md:mt-[120px] mt-0 lg:mt-0 2xl:mr-0 xl:mr-0'>
				<div className='flex flex-col-reverse md:flex-col mb-4 sm:w-full items-start z-20'>
					<h4 className='mb-4 mt-2 md:mt-0 w-full'>
						Šaltupio g. 7b, Vilnius, LT-02300, Lietuva
					</h4>
					<img
						onClick={() => window.open('https://goo.gl/maps/rg4KqLmfnQ65ugH78')}
						className='w-full cursor-pointer max-w-[370px]  h-auto border border-[#6666665e] sm:w-full sm:h-auto object-contain lg:hover:opacity-90'
						src={map}
						alt='map'
					/>
					<Partners />
				</div>
				<div className='sm:w-full max-w-[515px] sm:mb-10 '>
					<div className='flex flex-col gap-2 mb-4'>
						<a href='tel:+370 629 99984'>+370 629 99984</a>
						<a href='tel:+370 680 02029'>+370 680 02029</a>
					</div>
					<div className='mb-8 lg:mb-[38px]'>
						<a
							className='cursor-pointer'
							href='mailto:pardavimai@projectmini.lt'
						>
							pardavimai@projectmini.lt
						</a>
					</div>

					<form
						ref={formRef}
						className='flex flex-col gap-4 w-[95%] lg:gap-7 '
					>
						<div className='relative'>
							<input
								aria-label='first name input'
								className={`${
									name.error
										? 'border-b border-red-600 pb-[2px] focus-visible:outline-0 focus:border-b-2 focus:border-gray-300 w-full bg-transparent'
										: 'border-b border-gray-400 pb-[2px] focus-visible:outline-0 focus:border-b-2 focus:border-gray-300 w-full bg-transparent'
								}`}
								type='text'
								placeholder='Vardas*'
								value={name.value}
								name='from_name'
								onChange={(e) =>
									setName({ value: e.target.value, error: false, msg: '' })
								}
							/>
							{name.error && (
								<div className='dropdown dropdown-end absolute bottom-0'>
									<label
										tabIndex={0}
										className='btn btn-circle btn-ghost btn-xs text-info'
									>
										<img
											src={errorPng}
											alt=''
										/>
									</label>
									<div
										tabIndex={0}
										className='dropdown-content shadow w-max rounded-lg'
									>
										<div className='card-body bg-[#f3f4f6ef] rounded-lg py-2 px-4'>
											<p className='text-[20px]'>Klaida</p>
											<p>{name.msg}</p>
										</div>
									</div>
								</div>
							)}
						</div>
						<div className='relative'>
							<input
								aria-label='email input'
								className={`${
									email.error
										? 'border-b border-red-600 pb-[2px] focus-visible:outline-0 focus:border-b-2 focus:border-gray-300 w-full  bg-transparent'
										: 'border-b border-gray-400 pb-[2px] focus-visible:outline-0 focus:border-b-2 focus:border-gray-300 w-full  bg-transparent'
								}`}
								type='email'
								placeholder='El. pašto adresas*'
								value={email.value}
								name='from_email'
								onChange={(e) =>
									setEmail({ value: e.target.value, error: false, msg: '' })
								}
							/>
							{email.error && (
								<div className='dropdown dropdown-end absolute bottom-0'>
									<label
										tabIndex={0}
										className='btn btn-circle btn-ghost btn-xs text-info'
									>
										<img
											src={errorPng}
											alt=''
										/>
									</label>
									<div
										tabIndex={0}
										className='dropdown-content shadow w-max rounded-lg'
									>
										<div className='card-body bg-[#f3f4f6ef] rounded-lg py-2 px-4'>
											<p className='text-[20px]'>Klaida</p>
											<p>{email.msg}</p>
										</div>
									</div>
								</div>
							)}
						</div>
						<div className='relative'>
							<input
								aria-label='phone number input'
								className={`${
									phone.error
										? 'border-b border-red-600 pb-[2px] focus-visible:outline-0 focus:border-b-2 focus:border-gray-300 w-full bg-transparent'
										: 'border-b border-gray-400 pb-[2px] focus-visible:outline-0 focus:border-b-2 focus:border-gray-300 w-full bg-transparent'
								}`}
								style={{ MozAppearance: 'textfield' }}
								type='number'
								placeholder='Telefono numeris*'
								value={phone.value}
								name='from_phone'
								onChange={(e) =>
									setPhone({ value: e.target.value, error: false, msg: '' })
								}
							/>
							{phone.error && (
								<div className='dropdown dropdown-end absolute bottom-0'>
									<label
										tabIndex={0}
										className='btn btn-circle btn-ghost btn-xs text-info'
									>
										<img
											src={errorPng}
											alt=''
										/>
									</label>
									<div
										tabIndex={0}
										className='dropdown-content shadow w-max rounded-lg'
									>
										<div className='card-body bg-[#f3f4f6ef] rounded-lg py-2 px-4 max-w-[300px]'>
											<p className='text-[20px]'>Klaida</p>
											<p>{phone.msg}</p>
										</div>
									</div>
								</div>
							)}
						</div>
						<div>
							<textarea
								aria-label='message textarea'
								className='border-b border-gray-400 pb-[2px] min-h-[100px] max-h-[500px] focus-visible:outline-0 focus:border-b-2 focus:border-zinc-300 w-full bg-transparent'
								placeholder='Jūsų žinutė...'
								onChange={(e) =>
									setDesc({ value: e.target.value, error: false, msg: '' })
								}
								name='message'
								value={desc.value}
							/>
							{desc.error && (
								<p className='pl-2 pt-1 text-[14px] text-red-600'>{desc.msg}</p>
							)}
						</div>
					</form>
					<div className='flex items-center gap-2 mt-4 ml-2'>
						<input
							aria-label='agreement checkbox'
							type='checkbox'
							className={`checkbox checkbox-xs ${
								checkBoxError ? 'border-red-700' : ''
							}`}
							onChange={(e) => {
								setCheckBoxError(false);
								setCheckbox(e.target.checked);
							}}
							value={checkbox}
						/>
						<p className='text-[12px]'>Sutinku, kad su manimi susisiektų</p>
					</div>
					<button
						className={
							!success
								? 'px-8 py-2 text-white bg-[#002A32] mt-8 mb-12 lg:hover:scale-[1.05] duration-200 active:scale-[0.95] btn-font'
								: 'px-8 py-2 text-white bg-[#4D9078] mt-8 mb-12 lg:hover:scale-[1.05] duration-200 active:scale-[0.95] btn-font'
						}
						onClick={handleSubmit}
					>
						{!success ? 'Išsiųsti' : 'Išsiųsta'}
					</button>
				</div>
			</div>
			<div className='flex flex-col items-start ml-4 lg:ml-[120px] 2xl:ml-0'>
				<p className='text-[12px] font-medium'>
					© Project MINI. Visos teisės saugomos
				</p>
				<p className='text-[12px] font-medium'>© remodal. 2023</p>
			</div>
		</div>
	);
}

export default Footer;
