import React from 'react';
import tulsanaLogo from '../../assets/partners/Tulsana_Green_logo-500.webp';
import medzioBites from '../../assets/partners/medzio_bites.svg';
import euremsta from '../../assets/partners/euremsta_logo.png';

function Partners() {
	return (
		<div className='mt-4'>
			<h4>Partneriai:</h4>
			<div className='flex flex-wrap justify-center gap-4 items-start mt-2 mb-2'>
				<a
					rel='noreferrer'
					href='https://tulsana.lt'
					target='_blank'
				>
					<img
						className='h-16 grayscale opacity-50 duration-300 hover:grayscale-0 hover:opacity-100 cursor-pointer'
						src={tulsanaLogo}
						alt='tulsana logo'
					/>
				</a>
				<a
					rel='noreferrer'
					href='https://www.medziobites.lt'
					target='_blank'
				>
					<img
						className='h-14 grayscale opacity-50 duration-300 hover:grayscale-0 hover:opacity-100 cursor-pointer'
						src={medzioBites}
						alt='medzio bites logo'
					/>
				</a>
				<a
					rel='noreferrer'
					href='https://euremsta.lt'
					target='_blank'
				>
					<img
						className='h-14 grayscale opacity-50 duration-300 hover:grayscale-0 hover:opacity-100 cursor-pointer'
						src={euremsta}
						alt='euremsta logo'
					/>
				</a>
			</div>
		</div>
	);
}

export default Partners;
