import React from 'react';
import HorizontalColumn from '../Columns/HorizontalColumn';

function About() {
	return (
		<div
			id='about'
			className='max-w-[1200px] mx-auto relative'
		>
			<HorizontalColumn
				color='#FFEFE5'
				animation={'anime1'}
			/>
			<div className='flex flex-col gap-8 w-[56vw] h-max md:pt-[20vw] lg:pt-[12.5vw] mx-4 pt-[25vw] pb-[64px] md:pb-[144px] lg:pb-[124px] sm:pl-[64px] sm:items-end sm:w-[60.6%] sm:pr-[2vw] md:pr-[12px] lg:pr-[3vw] xl:pr-[1.5vw] sm:mr-auto '>
				<h2 className='text-[21px] lg:text-[28px] sm:mb-[32px]'>Apie mus</h2>
				<p className='text-[16px] leading-[26px] sm:text-right sm:mb-[64px]'>
					MB Project Mini specializuojasi modulinių pastatų gamyboje. Nameliai
					iki 50 kv.m. skirti gyvenimui, darbui ir poilsiui.{' '}
					<br className='block sm:hidden' />
					<br className='block sm:hidden' />
					Gaminame: modulinius namus, sodo namelius, sandėliukus, pavėsines,
					modulinius ofisus, pirtis ir kitą. <br className='block sm:hidden' />
					<br className='block sm:hidden' />
					Visa gamyba atliekama mūsų gamykloje, o Jums pristatomas gatavas
					produktas, jau paruoštas naudojimui. Papildomai teikiame vietos
					paruošimo, pamatų irengimo, pristatymo ir montavimo paslaugas.
				</p>

				<div className='gap-4 hidden md:flex'>
					<img
						className='w-[120px] h-[156px] object-cover'
						src={require('../../assets/img/mediena/wood.webp')}
						alt='material'
					/>
					<img
						className='w-[120px] h-[156px] object-cover'
						src={require('../../assets/img/mediena/wood2.webp')}
						alt='material'
					/>
					<img
						className='w-[120px] h-[156px] object-cover'
						src={require('../../assets/img/mediena/wood3.webp')}
						alt='material'
					/>
				</div>
			</div>
		</div>
	);
}

export default About;
