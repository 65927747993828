import React from 'react';

function VerticalColumn({height, color, className}) {
	return (
		<div
			className={`relative`}
			style={{
				height: height + 2,
			}}
		>
			<div
				className={`w-[12.5vw] lg:w-[10vw] bg-[#f8650a] ${className}`}
				style={{
					height: height + 2,
					backgroundColor: color,
				}}
			></div>
		</div>
	);
}

export default VerticalColumn;
