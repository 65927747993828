import house1 from '../../../assets/namukai/beautiful-country-house-exterior-interior-design-luxury-home-low-energy-family-house-made-wood.webp';
import house2 from '../../../assets/namukai/exterior-modern-small-house-with-wood-terrace-facade-3d-illustration.webp';
import house3 from '../../../assets/namukai/cabin-house-perspective-with-wooden-terrace-3d-rendering.webp';
import house4 from '../../../assets/namukai/entrance-modern-cottage-house-3d-rendering.webp';
import house5 from '../../../assets/namukai/exterior-black-modern-wooden-cabin-with-copy-space.webp';
import house6 from '../../../assets/namukai/black-wooden-chairs-cabin-terrace-with-mountain-view-sunset.webp';
import house7 from '../../../assets/namukai/modern-black-wooden-house-with-big-windows-carpathian-mountains-ukraine-with-copy-space.webp';
import house11 from '../../../assets/namukai/house-hotel-with-panoramic-windows-pine-forest-beautiful-summer-morning-with-light-sun-nature (2).webp';
import house9 from '../../../assets/namukai/house-hotel-with-panoramic-windows-pine-forest-beautiful-summer-morning-with-light-sun-nature (4).webp';
import house10 from '../../../assets/namukai/house-hotel-with-panoramic-windows-pine-forest-beautiful-summer-morning-with-light-sun-nature (3).webp';
import house8 from '../../../assets/namukai/mobile-house-iron-welded-frame-3d-illustration.webp';
import house12 from '../../../assets/namukai/house-hotel-with-panoramic-windows-pine-forest-beautiful-summer-morning-with-light-sun-nature (1).webp';
// import house13 from '../../../assets/namukai/scandinavian-house-with-panoramic-window-terrace-winter-forest (1).webp';
// import house14 from '../../../assets/namukai/small-aframe-cabin-made-light-wood-located-stee-zone.webp';
// import house15 from '../../../assets/namukai/street-conceptual-modern-tiny-house-with-panoramic-windows.webp';
// import house16 from '../../../assets/namukai/wooden-house-exterior-nature-view-elegant-window-architecture-design-3d-illustration.webp';
import house17 from '../../../assets/namukai/1.webp';
import house18 from '../../../assets/namukai/2.webp';
import house19 from '../../../assets/namukai/3.webp';
import house20 from '../../../assets/namukai/4.webp';

export const housesArr = [
	{
		title: 'Namelis Nr. 1',
		// desc: 'Šiuolaikišką ir modernų namelį pagaminsime pagal Jūsų poreikius.',
		li1: 'Bendras plotas: Pagal poreikius',
		li2: 'Projekto trukmė: iki 2 mėn.',
		li3: 'Kaina: 800 Eur/m²',
		img: [house18, house19, house20, house17],
	},
	{
		title: 'Namelis Nr. 2',
		// desc: 'Šiuolaikišką ir modernų namelį pagaminsime pagal Jūsų poreikius.',
		li1: 'Bendras plotas: Pagal poreikius',
		li2: 'Projekto trukmė: iki 2 mėn.',
		li3: 'Kaina: 800 Eur/m²',
		img: [house5, house6, house7, house8],
	},
	{
		title: 'Namelis Nr. 3',
		// desc: 'Šiuolaikišką ir modernų namelį pagaminsime pagal Jūsų poreikius.',
		li1: 'Bendras plotas: Pagal poreikius',
		li2: 'Projekto trukmė: iki 2 mėn.',
		li3: 'Kaina: 800 Eur/m²',
		img: [house9, house10, house11, house12],
	},
	{
		title: 'Namelis Nr. 4',
		// desc: 'Šiuolaikišką ir modernų namelį pagaminsime pagal Jūsų poreikius.',
		li1: 'Bendras plotas: Pagal poreikius',
		li2: 'Projekto trukmė: iki 2 mėn.',
		li3: 'Kaina: 800 Eur/m²',
		img: [house1, house2, house3, house4],
	},
];
