import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import VerticalColumn from './VerticalColumn';

function Columns() {
	const [trigger, setTrigger] = useState(false);

	const { sectionHeights } = useSelector((state) => state.generalSlice);

	useEffect(() => {
		const timer = setTimeout(() => {
			setTrigger(true);
		}, 600); // 1 second

		return () => {
			clearTimeout(timer);
		};
	}, []);

	const sectionAboutHeight = sectionHeights[0] + sectionHeights[1];
	const sectionOffersHeight = sectionAboutHeight + sectionHeights[2];
	const sectionProjectsHeight = sectionOffersHeight + sectionHeights[3];

	const sectionContactsHeight = sectionProjectsHeight + sectionHeights[4];

	return (
		<div className='relative'>
			{sectionHeights.length !== 0 && (
				<div className='flex absolute z-10 right-0'>
					<VerticalColumn
						height={sectionHeights[0]}
						color='#FFEFE5'
						className='animate-slide1'
					/>
					<VerticalColumn
						height={sectionAboutHeight}
						color='#F1DDD1'
						className='animate-slide2'
					/>
					<VerticalColumn
						height={sectionOffersHeight}
						color='#DFC1AE'
						className='animate-slide3'
					/>
					<VerticalColumn
						height={sectionProjectsHeight}
						color='#BE9D88'
						className='animate-slide4'
					/>
					<div
						className={`hidden lg:block lg:w-[10vw] bg-[#9C7B67] animate-slide5`}
						style={{
							height: sectionContactsHeight,
						}}
					></div>
				</div>
			)}
		</div>
	);
}

export default Columns;
