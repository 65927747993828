import sauna1 from '../../../assets/pirtys/empty-wooden-sauna-room-with-traditional-sauna-accessories.webp';
import sauna2 from '../../../assets/pirtys/room-with-window-bench-middle-room.webp';
import sauna3 from '../../../assets/pirtys/sauna-made-natural-wood-with-large-panoramic-window.webp';
import sauna4 from '../../../assets/pirtys/turkish-bath-sauna-real-estate-agents.webp';
import sauna5 from '../../../assets/pirtys/sauna5.webp';
import sauna6 from '../../../assets/pirtys/sauna6.webp';
import sauna7 from '../../../assets/pirtys/sauna7.webp';
import sauna8 from '../../../assets/pirtys/sauna8.webp';

export const saunasArr = [
	// {
	// 	title: 'Pirtis Nr. 1',
	// 	// desc: 'Gaminame modulinius namelius pagal Jūsų norus. Galime įmontuoti šikaną, saulės batarkes ir pan. Tik būkit geri turėkit daug babkių.',
	// 	li1: 'Bendras plotas: Pagal poreikius',
	// 	li2: 'Projekto trukmė: iki 2 mėn.',
	// 	li3: 'Kaina: 600 Eur/m²',
	// 	// area: 'Projektuojame pagal individualius pageidavimus.',
	// 	img: [sauna5, sauna6, sauna7, sauna8],
	// },
	{
		title: 'Pirtis Nr. 1',
		// desc: 'Gaminame modulinius namelius pagal Jūsų norus. Galime įmontuoti šikaną, saulės batarkes ir pan. Tik būkit geri turėkit daug babkių.',
		li1: 'Bendras plotas: Pagal poreikius',
		li2: 'Projekto trukmė: iki 2 mėn.',
		li3: 'Kaina: 600 Eur/m²',
		// area: 'Projektuojame pagal individualius pageidavimus.',
		img: [sauna1, sauna2, sauna3, sauna4],
	},
];
