import React from 'react';
import HorizontalColumn from '../Columns/HorizontalColumn';
import Accordion from './Accordion';
import MainAccordion from './MainAccordion';
import { useDispatch, useSelector } from 'react-redux';
import {
	setCalculateHeight,
	setOpenAccordionId,
} from '../../store/generalStore';

function Offers() {
	const dispatch = useDispatch();
	const { openAccordionId, calculateHeight } = useSelector(
		(state) => state.generalSlice
	);

	const handleAccordionClick = (id) => {
		dispatch(setCalculateHeight(!calculateHeight));
		const filter = openAccordionId === id ? null : id;
		dispatch(setOpenAccordionId(filter));
	};

	const data = [
		{
			id: 1,
			title: 'Kvadratūra',
			desc: '18 kv. m (6 x 3m), naudingas plotas.',
		},
		{
			id: 2,
			title: 'Namo konstrukcija',
			desc: 'Gaminama iš sertifikuotos C24 kalibruotos medienos',
		},
		{
			id: 3,
			title: 'Pagrindas',
			desc: '20 cm impregnuotos gegnės, apšiltintos 20 cm akmens vata, difuzinė plėvelė, jūrinė faniera su oro tarpu iš apačios, 22 mm OSB iš viršaus.',
		},
		{
			id: 4,
			title: 'Išorės apdaila',
			desc: '3 sienos Classic skarda, priekyje dažytos dailylentės',
		},
		{
			id: 5,
			title: 'Sienos',
			desc: '15 cm C24 gegnės vertikaliai + papildomai 5 cm horizontaliai, viskas apšiltinta 20 cm akmens vata, difuzinė plėvelė iš išorės, priešgarinė plėvelė iš vidaus.',
		},
		{
			id: 6,
			title: 'Stogo danga',
			desc: 'Classic skarda.',
		},
		{
			id: 7,
			title: 'Stogas',
			desc: '20 cm kalibruota mediena, 20 cm akmens vatos apšiltinimo sluoksnis, difuzinė plėvelė iš išorės, priešgarinė plėvelė iš vidaus.',
		},
		{
			id: 8,
			title: 'Išvedžiota santechnika',
			desc: 'WC (dušas, praustuvas, tualetas, boileris), virtuvė (kriauklė, skalbimo mašina/indaplovė).',
		},
		{
			id: 9,
			title: 'Elektra',
			desc: 'Įrengta pilna elektros instaliacija (LED apšvietimas: jungtukai, kištukiniai lizdai, paskirstymo dėžė su automatiniais saugikliais ir nuotėkio relė).',
		},
	];

	return (
		<div
			id='offers'
			className='container mx-auto flex justify-start relative xl:h-[840px]'
		>
			<HorizontalColumn
				color='#F1DDD1'
				animation={'anime2'}
			/>

			<div className=' flex flex-col xl:flex-row xl:w-[60vw] 2xl:w-[50vw] w-[70vw] md:w-[65vw] ml-4 md:ml-8 pt-[12.5vw] lg:pt-[12.5vw] md:pt-[20vw] xl:mt-8 pb-[6vw]  lg:z-20 z-[20]'>
				<MainAccordion title='Mūsų gaminamų modulinių namų privalumai' />
				<div className='xl:block xl:w-[40%] hidden'>
					{data.map((obj) => (
						<Accordion
							key={obj.id}
							id={obj.id}
							title={obj.title}
							description={obj.desc}
							isOpen={openAccordionId}
							onClick={handleAccordionClick}
						/>
					))}
				</div>
				<div className='hidden xl:block xl:w-[56%]'>
					<h3 className='text-[21px] mb-4 font-400'>
						Mūsų gaminamų modulinių namų privalumai:
					</h3>
					<ul className='px-4 pl-10 py-2 list-disc'>
						<li className='mb-1'>
							Naudojamos tik aukščiausios kokybės medžiagos
						</li>
						<li className='mb-1'>Gamyba atliekama mūsų gamykloje</li>
						<li className='mb-1'>Greita statyba</li>
						<li className='mb-1'>Nereikalingi jokie leidimai</li>
						<li className='mb-1'>
							Galimybė sujungti kelis modulinius pastatus
						</li>
						<li className='mb-1'>
							Tinkamai apšiltintas namas tinka gyvenimui žiemą
						</li>
						<li className='mb-1'>
							Mobilus - galima perkelti į kitą norimą vietą, lengvai
							transportuojamas
						</li>
						<li className='mb-1'>Greitas pristatymas</li>
						<li className='mb-1'>Ekologiškas pasirinkimas</li>
						<li className='mb-1'>
							Projektai koreguojami pagal poreikius ir pageidavimus
						</li>
						<li className='mb-1'>
							Teikiame vietos paruošimo, pamatų irengimo, pristatymo ir
							montavimo darbų paslaugas
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}

export default Offers;
